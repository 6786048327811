/* Live */
export const appId = "5FA86840-267F-4AA9-A263-127B4E397DFD";
export const projectTitle = "The Hainan Story";
export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
//export const baseUrl = "https://hainonstory.promobuddy.asia/";
export const baseUrl = "https://thehainanstory.com/";

export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "The Hainan Story";

export const fbAppId = "1640793372780977";

export const stripeKey = "pk_test_mSKQhHZYbRvm1YvuX9mKN4zx";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "hainanstory";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const currencySymbol = "$";
