import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import ProductDetail from "./components/Products/ProductDetail";
import FavouriteProducts from "./components/Products/FavouriteProducts";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Process from "./components/Checkout/Process";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
/* import Outlets from "./components/Pages/Outlets"; */
import Faq from "./components/Pages/Faq";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Myvoucher from "./components/Myaccount/Myvoucher";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/sign-in" component={Home} />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/menu/:slugType/:slugValue"} component={Products} />
        <Route path="/menu" component={Products} />
        <Route path="/favourite" component={FavouriteProducts} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/faild/:orderId" component={Process} />
        <Route path="/about-us" component={Pages} />
        <Route path="/our-brands/the-hainan-story-bakery" component={Pages} />
        <Route
          path="/our-brands/the-hainan-story-coffee-toast"
          component={Pages}
        />
        <Route path="/our-brands/the-golden-porridge" component={Pages} />
        <Route path="/our-brands/hainanese-white-kway-chap" component={Pages} />
        <Route
          path="/our-brands/newspaper-hainanese-curry-rice"
          component={Pages}
        />
        <Route path="/our-brands/wee-nam-kee-chicken-rice" component={Pages} />
        <Route path="/our-brands" component={Pages} />
        <Route path="/whats-new" component={Pages} />
        <Route path="/dine-in/the-introduction" component={Pages} />
        <Route path="/dine-in/chapter-one" component={Pages} />
        <Route path="/dine-in" component={Pages} />
        <Route path="/e-shop/delivery" component={Pages} />
        <Route path="/e-shop/takeaway" component={Pages} />
        <Route path="/e-shop" component={Pages} />
        <Route path="/media" component={Pages} />
        <Route path="/gallery" component={Pages} />
        <Route path="/our-team" component={Pages} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/contact-us/headquarter" component={Pages} />
        <Route path="/contact-us/outlets-location" component={Pages} />
        <Route path="/contact-us/feedback" component={ContactUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/faq" component={Faq} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/voucher" component={Myvoucher} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
