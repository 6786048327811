/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import cookie from "react-cookies";
import Slider from "react-slick";
import { appId, apiUrlV2, deliveryId } from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showPriceValueSub,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";
import titleleft from "../../../common/images/header-symbol.png";
import titleright from "../../../common/images/header-symbol-btm.png";

import { GET_FEATUREPRO } from "../../../actions";
var qs = require("qs");
var Parser = require("html-react-parser");
var settingsGallery = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class FeaturedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.getFeatureProList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndexF-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndexF-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        console.log(
          actionFlg,
          $("#proIndexF-" + IndexFlg).length,
          $("#proIndexF-" + IndexFlg).find(".smiple_product_lk").length,
          $("#proIndexF-" + IndexFlg).find(".addcart_done_maindiv").length,
          "actionFlgactionFlgactionFlg"
        );
        $("#proIndexF-" + IndexFlg).addClass("active");
        $("#proIndexF-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndexF-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndexF-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndexF-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndexF-" + IndexFlg).removeClass("active");
          hideLoader("proIndexF-" + IndexFlg, "Idtext");
          $("#proIndexF-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndexF-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        this.props.history.push(
          "/menu/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productSlug
        );
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let featureproArr = this.props.featureproduct;
    let featureprolist = [];
    let featureproimagesource = "";
    let tagimagesource = "";

    if (Object.keys(featureproArr).length > 0) {
      if (featureproArr[0].status === "ok") {
        featureprolist = featureproArr[0].result_set;
        featureproimagesource = featureproArr[0].common.image_source;
        tagimagesource = featureproArr[0].common.tag_image_source;
      }
    }
    var settingsProduct = {
      items: 4,
      autoplay: false,
      margin: 18,
      loop: true,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
        },
        520: {
          items: 2,
          margin: 10,
        },
        780: {
          items: 3,
          margin: 12,
        },
        1280: {
          items: 4,
        },
      },
    };

    return (
      <section className="featuredpro-section">
        {Object.keys(featureprolist).length > 0 ? (
          <div className="featured-products-section">
            <div className="container">
              {
                /* this.props.stayhomeblk !== "" && ( */
                <div className="fproducts-title-img">
                  <div className="fproducts-title-img-inner">
                    <img className="tl" src={titleleft} />
                    <img className="tr" src={titleleft} />
                    <img className="tlt" src={titleright} />
                    <img className="trb" src={titleright} />
                    <h2>Our Special Signatures</h2>
                  </div>
                </div>
                /* ) */
              }
              <div className="featured-products-main">
                <div className="products-list-ulmain">
                  <Slider {...settingsGallery}>
                    {featureprolist.map((featurepro, index) => {
                      var prodivId =
                        "proIndexF-" + featurepro.product_primary_id;
                      var comboProId = "comboPro-" + featurepro.product_slug;
                      return (
                        <div
                          className="products-single-li"
                          id={prodivId}
                          key={index}
                        >
                          <div className="products-image-div">
                            {featurepro.product_thumbnail !== "" ? (
                              <img
                                src={
                                  featureproimagesource +
                                  "/" +
                                  featurepro.product_thumbnail
                                }
                              />
                            ) : (
                              <img src={noimage} />
                            )}
                          </div>

                          <div className="product-info-div">
                            <div className="product-title-maindiv">
                              <div className="product-title">
                                <h3>
                                  {featurepro.product_alias !== ""
                                    ? stripslashes(featurepro.product_alias)
                                    : stripslashes(featurepro.product_name)}
                                </h3>
                              </div>
                              {Object.keys(featurepro.product_tag).length >
                              0 ? (
                                <div className="product-tag-list">
                                  <ul>
                                    {featurepro.product_tag.map(
                                      (producttag, index1) => {
                                        return (
                                          <li key={index1}>
                                            {producttag.pro_tag_name !== "" &&
                                              producttag.pro_tag_name}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="product-short-description">
                              <p>
                                {featurepro.product_short_description != ""
                                  ? Parser(
                                      stripslashes(
                                        featurepro.product_short_description
                                      )
                                    )
                                  : Parser("")}
                              </p>
                            </div>

                            <div className="products-ordernow-action">
                              <div className="products-ordernow-inner">
                                <div className="product-price">
                                  <h3>
                                    {parseFloat(featurepro.product_cost) >
                                      0 && (
                                      <span className="price_disc">
                                        {showPriceValueSub(
                                          featurepro.product_cost
                                        )}
                                      </span>
                                    )}
                                    {showPriceValue(featurepro.product_price)}
                                  </h3>
                                </div>
                                <div
                                  className="addcart_row addcart_done_maindiv"
                                  style={{ display: "none" }}
                                >
                                  <div className="addcart-inner-flex">
                                    <div className="qty_bx">
                                      <span
                                        className="qty_minus"
                                        onClick={this.proQtyAction.bind(
                                          this,
                                          featurepro.product_primary_id,
                                          "decr"
                                        )}
                                      >
                                        -
                                      </span>
                                      <input
                                        type="text"
                                        className="proqty_input"
                                        readOnly
                                        value="1"
                                      />
                                      <span
                                        className="qty_plus"
                                        onClick={this.proQtyAction.bind(
                                          this,
                                          featurepro.product_primary_id,
                                          "incr"
                                        )}
                                      >
                                        +
                                      </span>
                                    </div>
                                    <button
                                      className="btn btn_black order_done"
                                      onClick={this.addToCartSimple.bind(
                                        this,
                                        featurepro,
                                        "done"
                                      )}
                                    >
                                      Done
                                    </button>
                                  </div>
                                </div>

                                {featurepro.product_stock > 0 ||
                                featurepro.product_stock === null ? (
                                  featurepro.product_type === "1" ? (
                                    <a
                                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                                      href="/"
                                      onClick={this.addToCartSimple.bind(
                                        this,
                                        featurepro,
                                        "initial"
                                      )}
                                    >
                                      Order Now
                                    </a>
                                  ) : (
                                    <a
                                      href="/"
                                      onClick={this.viewProDetail.bind(
                                        this,
                                        featurepro
                                      )}
                                      title="Product Details"
                                      id={comboProId}
                                      className="button order_nowdiv compo_product_lk"
                                    >
                                      Order Now
                                    </a>
                                  )
                                ) : (
                                  <a
                                    className="button disabled disbl_href_action"
                                    href="/"
                                  >
                                    Sold Out
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    featureproduct: state.featureproduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatureProList: () => {
      dispatch({ type: GET_FEATUREPRO });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FeaturedProducts);
