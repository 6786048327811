/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { stripslashes } from "../../Helpers/SettingHelper";
import { GET_TESTIMONIAL } from "../../../actions";
import titleleft from "../../../common/images/header-symbol.png";
import titleright from "../../../common/images/header-symbol-btm.png";

var settingsTestimonial = {
  items: 3,
  center: true,    
  autoplay: false,
  margin: 50,
  loop: true,
  nav: false,
  dots: true,
   
  responsive: {
    0: {
      items: 1,
      autoHeight:true,       
    },
    780: {
      items: 2,
    },
    1280: {
      items: 3,
    },
  },
};
class Testimonial extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBlogList();
  }
  render() {
    let testimonialArr = this.props.testimonial;
    let testimoniallist = [];

    if (Object.keys(testimonialArr).length > 0) {
      if (testimonialArr[0].status === "ok") {
        testimoniallist = testimonialArr[0].result_set;
      }
    }
    return (
      <>
        {Object.keys(testimoniallist).length > 0 && (
          <div className="client_riv">
            <div className="container">
              <div className="fproducts-title-img">
                  <div className="fproducts-title-img-inner">
                     <img className="tl" src={titleleft} />
                      <img className="tr" src={titleleft} />
                      <img className="tlt" src={titleright} />
                      <img className="trb" src={titleright} />
                     <h2>What  They Said</h2>
                  </div>
                </div>
              <div className="client_riv_slider">
                <OwlCarousel options={settingsTestimonial}>
                  {testimoniallist.map((item, index) => {
                    return (
                      <div className="client_riv_item" key={index}>
                        {item.testimonial_description !== "" &&
                          item.testimonial_description !== null && (
                            <p>{stripslashes(item.testimonial_description)}</p>
                          )}
                        <h5>{stripslashes(item.testimonial_tittle)}</h5>
                        {item.testimonial_company !== "" && (
                          <p className="client_riv_stitle">
                            {stripslashes(item.testimonial_company)}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    testimonial: state.testimonial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBlogList: () => {
      dispatch({ type: GET_TESTIMONIAL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Testimonial);
