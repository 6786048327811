/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { appId, apiUrl } from "../Helpers/Config";
import { removePromoCkValue, showAlert } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
var qs = require("qs");
class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.checkPaymentStatus();
  }

  checkPaymentStatus() {
    if (
      this.props.match.params.orderId !== "" &&
      typeof this.props.match.params.orderId !== undefined &&
      typeof this.props.match.params.orderId !== "undefined"
    ) {
      var cabtureObjects = {
        app_id: appId,
        data_oid: this.props.match.params.orderId,
      };
      axios
        .post(
          apiUrl + "firstdata/checkingPaymentStatus",
          qs.stringify(cabtureObjects)
        )
        .then((captureRes) => {
          console.log(captureRes.data, "captureRes.data");
          if (captureRes.data.status === "ok") {
            var omsMsgTxt =
              captureRes.data.message != ""
                ? captureRes.data.message
                : "Your order was not successful.";
            showAlert("Error", omsMsgTxt);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            this.props.history.push("/");
            return;
          } else if (captureRes.data.status === "error") {
            var omsMsgTxt =
              captureRes.data.message != ""
                ? captureRes.data.message
                : "Your order was not successful.";
            showAlert("Error", omsMsgTxt);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            this.props.history.push("/");
            return false;
          } else {
          }
        })
        .catch((error) => {
          showAlert("Error", error);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.props.history.push("/");
          return false;
        });
    } else {
      this.props.history.push("/");
      return false;
    }
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    const { history } = this.props;
    $.magnificPopup.close();
    history.push("/thankyou/" + localOrderNo);
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("reference_number", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    /* cookie.remove("orderOutletName"); */
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    /*  cookie.remove("deliveryDate");
    cookie.remove("deliveryTime"); */
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("promoOutlet", { path: "/" });
    cookie.remove("promoType", { path: "/" });
    cookie.remove("promoPercentage", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }
  render() {
    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Placing Order</p>
          </div>
        </div>

        <div id="dvLoading"></div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Process);
